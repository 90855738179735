@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar but keep functionality */
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  
  .no-scrollbar::-webkit-scrollbar {
    display: none;  /* Chrome, Safari and Opera */
  }
  
  /* Custom thin scrollbar */
  .thin-scrollbar::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }
  
  .thin-scrollbar::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .thin-scrollbar::-webkit-scrollbar-thumb {
    background: rgba(100, 100, 100, 0.5);
    border-radius: 4px;
  }
  
  .thin-scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(100, 100, 100, 0.7);
  }
  
  .thin-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: rgba(100, 100, 100, 0.5) transparent;
  }
}

/* Markdown-style formatting for chat messages */
.markdown-message strong {
  font-weight: 700;
}

.markdown-message em {
  font-style: italic;
}

.markdown-message code {
  font-family: 'Courier New', Courier, monospace;
  background-color: rgba(55, 65, 81, 0.8);
  padding: 0.1rem 0.3rem;
  border-radius: 0.25rem;
  font-size: 0.9em;
}

.markdown-message a {
  color: #3B82F6;
  text-decoration: none;
}

.markdown-message a:hover {
  text-decoration: underline;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0A192F;
  background-image: 
    linear-gradient(to right, rgba(100, 255, 218, 0.05) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(100, 255, 218, 0.05) 1px, transparent 1px);
  background-size: 20px 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
